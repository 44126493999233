@use 'src/styles/general/variables' as *;
@use 'src/styles/utils/mixins' as *;

.input {
  font-weight: 400;
  @include adaptive-fz(font-size, 14, 10);
  line-height: 125%;

  &__label {
    @include adaptive(margin-bottom, 20);
    display: block;

    &__star {
      color: $green;
    }

    &__text, &__code {
      color: $primary-black;
    }

    &__confirm {
      color: $primary-gray-3;
    }
  }

  &__field {
    @include adaptive-px(18);
    @include adaptive-py(16);
    @include adaptive(height, 56);
    width: 100%;
    border: 1px solid #D8DADC;
    position: relative;
    @include adaptive(border-radius, 10);
    justify-content: space-between;

    input {
      color: $primary-black;
      width: 100%;

      &::placeholder {
        color: $primary-gray-3;
      }
    }

    button {
      @include adaptive(max-width, 20);
      @include adaptive(max-height, 20);
    }

    &__icon {
      @include adaptive(right, 18px); 
      @include icon(20, 20);
      
      &[data-pass-toggle] {
        cursor: pointer;
      }
    }

    &--code {
      border: 1px solid $primary-black;
    }
  }
}

// CheckBox
.checkbox {
  font-weight: 400;
  @include adaptive-fz(font-size, 13, 10);
  line-height: 125%;
  color: $primary-black;
  @include adaptive(margin-bottom, 20);

  &__input {
    + label::before {
      content: '';
      display: inline-block;
      background-image: url('src/assets/media/static/icons/unchecked.svg');
      width: 2.8rem;
      height: 2.8rem;
      flex-shrink: 0;
      flex-grow: 0;
      background-repeat: no-repeat;
      background-position: center center;
      cursor: pointer;
      transition: .1s;
    }

    &:checked {
      + label::before {
        background-image: url('src/assets/media/static/icons/checked.svg');
        width: 2.8rem;
        height: 2.8rem;
        border: 0;
        margin: 0;
        filter: $filter-blue;
        transition: .1s;
      }
    }
  }
}

// ------------------------------------------
@media only screen and (max-width: 1100px) {
  // от 1100 (table), 375 и ниже
  .input {
    @include adaptive-fz(font-size, 14, 3, $max: 15px);
  }

  .checkbox {
    @include adaptive-fz(font-size, 13, 3, $max: 14px);
  }
}