@use 'src/styles/general/variables' as *;
@use 'src/styles/utils/mixins' as *;

.header-search {
  width: 100%;
  @include adaptive(height, 70);
  background: $green;
  border-radius: 0px 0px 8px 8px;
  @include adaptive-p(20);
  position: relative;

  &__form {
    display: flex;

    &__input {
      height: fit-content;
      width: auto;
      display: inline-flex;
      align-items: center;
      flex-basis: 60%;

      &--icon {
        @include icon();
        filter: $filter-white;
      }

      &--field {
        width: 100%;
        @include adaptive(height, 28);
        @include adaptive(gap, 8);

        &,
        &::placeholder {
          color: $white;
          font-weight: 600;
          @include adaptive-fz(font-size, 14, 10);
          @include adaptive(line-height, 17);
        }
      }
    }
  }

  &__result {
    position: absolute;
    z-index: 30;
    @include adaptive(right, 1);
    border-radius: 0px 0px 8px 8px;
    //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    @include adaptive(height, 327);
    //@include adaptive-py(24);
    //@include adaptive-px(20);
    width: 100%;
    background: $white;
    @include adaptive(margin-top, 20);
    display: none;
    
    &.--opened {
      display: block;
    }

    .wrapper {
      @include adaptive-py(24);
      @include adaptive-px(20);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 0px 0px 8px 8px;
    }

    &__inner {
      $height: 327 - 24 * 2;
      @include adaptive(height, $height);
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 9px;
      }

      &::-webkit-scrollbar-track {
        background: $primary-gray-4;
        border-radius: 12px;
      }

      &::-webkit-scrollbar-thumb {
        background: $primary-black;
        border-radius: 12px;
      }

      &--text {
        color: $primary-black;
        font-weight: 500;
        @include adaptive-fz(font-size, 13, 10);
        @include adaptive(line-height, 16);
      }

      &--list {
        padding: 0;
        margin: 0;
        @include adaptive(margin-top, 36);
        list-style-type: none;
        @include adaptive(gap, 12);

        &__item {
          .icon {
            @include icon(36, 36);
            border-radius: 4px;
            @include adaptive(margin-right, 8);
          }

          .text {
            color: $primary-black;
            font-weight: 500;
            @include adaptive-fz(font-size, 13, 10);
            @include adaptive(line-height, 16);
          }
        }
      }
    }

    &__mobile {
      display: none;
    }
  }
}

// ------------------------------------------
@media only screen and (max-width: 1100px) {
  // от 1100 (table), 375 и ниже
  .header-search {
    @include adaptive-fz(height, 70, $max: 70px);
    @include adaptive-fz(padding, 20, $min: 15px, $max: 25px);
    
    &__form {
      &__input {
        flex-basis: 80%;

        &--icon {
          @include adaptive-fz(height, 28, $max: 28px);
          @include adaptive-fz(width, 28, $max: 28px);
        }

        &--field {
          &,
          &::placeholder {
            @include adaptive-fz(font-size, 14, 2, $max: 15px);
            @include adaptive(line-height, 17);
          }  
        }
      }
    }

    &__result {
      height: auto;

      &__inner {
        &--text {
          @include adaptive-fz(font-size, 13, 3, $max: 14px);
        }

        &--list {
          &__item{
            .text {
              @include adaptive-fz(font-size, 13, 3, $max: 14px);
            }
          }
        }
      }

      &__mobile {
        display: flex;
        @include adaptive(gap, 36);
        @include adaptive(margin-top, 36);
        @include adaptive-py(24);
        @include adaptive-px(20);

        .swiper-search-result {
          .swiper-wrapper {
            .swiper-slide {
              background: $green;
              border-radius: 8px;
              position: relative;

              &.--small {
                //@include adaptive-fz(width, 215, $max: 216px);
                //@include adaptive-fz(height, 178, $max: 179px);  
                width: 215px !important;
                height: 178px !important;
              }

              &.--big {
                //@include adaptive-fz(width, 399, $max: 400px);
                //@include adaptive-fz(height, 239, $max: 240px);  
                width: 399px !important;
                height: 239px !important;
              }

              .label {
                @include adaptive-p(4);
                border-radius: 4px;
                background: $primary-black;
                color: $white;
                font-weight: 400;
                @include adaptive-fz(font-size, 14, 2, $max: 15px);
                @include adaptive(line-height, 22);
                position: absolute;
                @include adaptive(top, 4);
                @include adaptive(right, 8);
              }
            }
          }
        }

        &__tags {
          @include adaptive(gap, 12);
          font-weight: 600;
          @include adaptive-fz(font-size, 14, 3, $max: 15px);
          @include adaptive(line-height, 17);

          .title {
            color: $primary-gray-3;
          }

          .tag {
            color: $primary-gray-2;
          }
        }

        &__text {
          display: block;
          color: $primary-black;
          font-weight: 500;
          @include adaptive-fz(font-size, 13, 3, $max: 14px);
          @include adaptive(line-height, 16);
        }

        &__link {
          &--icon {
            @include icon();
          }

          &--text {
            color: $primary-black;
            font-weight: 600;
            @include adaptive-fz(font-size, 14, 3, $max: 15px);
            @include adaptive(line-height, 17);
          }
        }
      }
    }
  }
}