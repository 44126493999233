@use 'src/styles/general/variables' as *;
@use 'src/styles/utils/mixins' as *;

.breadcrumb {
  @include adaptive(gap, 12);
  font-weight: 400;
  @include adaptive-fz(font-size, 13, 10, $max: 14px);
  @include adaptive(line-height, 16);
  color: #727272;
  list-style-type: none;
  padding: 0;
  margin: 0;

  .breadcrumb__item {
    @include adaptive-py(4);
    @include adaptive-px(8);
    border: 1px solid $gray-light;
    border-radius: 4px;
  }

  .breadcrumb__item__icon {
    img {
      @include icon();
      filter: invert(44%) sepia(0%) saturate(0%) hue-rotate(229deg) brightness(101%) contrast(94%);  
    }
  }
}

// ------------------------------------------
@media only screen and (max-width: 1100px){
  // от 1100 (table), 375 и ниже
  .breadcrumb {
    @include adaptive(gap, 4);
    @include adaptive-fz(font-size, 13, 3, $max: 14px);
  }
}