@use 'src/styles/general/variables' as *;
@use 'src/styles/utils/mixins' as *;

// Panel
@use 'panel';
// Search
@use 'search';
// Menu
@use 'menu';

.header {
  //padding: 10px 80px;
  @include adaptive-px(80);
  @include adaptive-py(10);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  button[data-action=open-mob-menu] {
    display: none;
  }

  .wrapper /*, .flex-row*/ {
    .header__logo {
      @include icon(135, 58);
      @include adaptive(margin-right, 64);
      filter: $filter-green;
      //margin-right: 64px;
    }

    .header__search-form {
      .header__search-form__input {
        height: fit-content;
        width: fit-content;
        display: inline-flex;
        align-items: center;

        .header__search-form__input__icon {
          @include icon();
        }

        .header__search-form__input__field {
          @include adaptive(width, 450);
          @include adaptive(height, 28);
          @include adaptive(gap, 8);
          //width: 450px;
          //height: 28px;
          //gap: 8px;

          &,
          &::placeholder {
            color: $primary-gray-3;
            font-weight: 600;
            @include adaptive-fz(font-size, 14, 10);
            @include adaptive(line-height, 22);
            //font-size: 14px;
            //line-height: 22px;
          }
        }
      }
    }
  }

  .wrapper /*, .flex-inline */ {
    .header__mobile-actions {
      display: none;
    }

    .header__register {
      background: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      //width: 188px;
      //height: 43px;

      span {
        color: $primary-gray-2;
        font-weight: 500;
        @include adaptive-fz(font-size, 14, 10);
        @include adaptive(line-height, 28);
        //font-size: 14px;
        //line-height: 28px;
        text-decoration-line: underline;
      }
    }

    .header__login {
      //width: 188px;
      //height: 43px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $green;
      border-radius: 5px;

      span {
        color: $white;
        font-weight: 600;
        @include adaptive-fz(font-size, 14, 10);
        @include adaptive(line-height, 22);
        //font-size: 14px;
        //line-height: 22px;
      }
    }

    .header__register,
    .header__login {
      @include adaptive(width, 188);
      @include adaptive(height, 43);
      //width: 188px;
      //height: 43px;
    }
  }
}

// ------------------------------------------
@media only screen and (max-width: 1700px) {

  //от 1440 до 1280
  .header {
    .wrapper/*, .flex-inline*/ {
      .header__register,
      .header__login {
        @include adaptive(width, 128);
      }
    }
  }
}

// ------------------------------------------
@media only screen and (max-width: 1380px) {

  // от 1280 до 375
  .header {
    @include adaptive-px(60);
    @include adaptive-py(10);
    //padding: 10px 60px;

    .wrapper/*, .flex-inline*/ {
      .header__register {
        flex-basis: 128px;
        flex-shrink: 2;
      }

      .header__login {
        flex-shrink: 1;
      }

      .header__register,
      .header__login {
        //@include adaptive(min-width, 107);
        //@include adaptive(min-width, 107);
        @include adaptive(width, 128);
      }
    }
  }
}

// ------------------------------------------
@media only screen and (max-width: 1100px) {

  // от 1100 (table), 375 и ниже
  .header {
    @include adaptive(margin-top, 20);
    @include adaptive-px(16);
    padding-top: 0;
    padding-bottom: 0;
    //margin-top: 20px;
    //padding: 0 16px;

    button[data-action=open-mob-menu] {
      display: block;
      
      .header__menu-icon {
        @include icon(58, 58);
      }
    }

    .wrapper/*, .flex-row*/ {
      .header__logo {
        margin: 0;
        @include adaptive(width, 113);
        @include adaptive(height, 44);
        //width: 113px;
        //height: 44px;
      }

      .header__search-form {
        display: none;
      }
    }

    .wrapper /*, .flex-inline*/ {
      .header__mobile-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        @include adaptive-p(4);
        @include adaptive(gap, 12);
        //padding: 4px;
        //gap: 12px;
        width: fit-content;
        @include adaptive(height, 43);
        //height: 43px;
        border: 1px solid $border-color;
        border-radius: 5px;

        .header__mobile-actions__icon {
          @include icon();
          transform: rotate(90deg);
          filter: $filter-gray-3;
        }

        .header__mobile-actions__circle {
          @include adaptive-circle(35);
          background: $green;
        }
      }

      .header__register,
      .header__login {
        display: none;
      }
    }
  }
}

/*@media only screen and (min-width: 375px) and (max-width: 1024px){
  .header{
    .header-container {
      margin-top: 20px;
      padding: 0 16px;

      .menu-icon {
        display: block;
        @include icon(58px, 58px);
      }

      .wrapper__logo-form {
        .header__logo {
          margin: 0;
          width: 113px;
          height: 44px;
        }

        .header__search-form {
          display: none;
        }
      }

      .wrapper__actions {
        .mobile-actions {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 4px;
          gap: 12px;
          width: fit-content;
          height: 43px;
          border: 1px solid $border-color;
          border-radius: 5px;

          .arrow-icon {
            @include icon();
          }

          .circle {
            @include circle(35px);
            background: $green;
          }
        }

        .register, .login {
          display: none;
        }
      }
    }
  }
}

// min-width: 1024px

@media only screen and (min-width: 1280px) and (max-width: 1439px){
  .header {
    .header-container {
      padding: 10px 60px;
    }
  }
}

@media only screen and (min-width: 1440px){

}*/
