html {
  overflow-x: clip;
  font-size: 62.5%;
  scroll-behavior: smooth;
}

.container {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1230px;
}

.main-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.main {
  flex: 1 1 0;
}

.hidden {
  display: none;
}

body {
  overflow-x: clip;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  padding: 0;
  margin: 0;
  background: #fff;
}

html.disable-scroll {
  overflow: clip;

  body {
    overflow: clip;
  }
}

.swiper,
.swiper-wrapper,
.swiper-slide {
  box-sizing: border-box !important;
}

a {
  color: #004677;
}

@media only screen and (max-width: 750px) {
  .disable-scroll {
    body {
      padding-right: 0;
    }
  }
}

// flex wrap
.flex {
  display: flex;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-col-reverse {
  display: flex;
  flex-direction: column-reverse;
}

.flex-inline {
  display: inline-flex;
}
