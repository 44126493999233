@use 'src/styles/general/variables' as *;
@use 'src/styles/utils/mixins' as *;

.header-panel {
  @include adaptive(gap, 12);
  @include adaptive(width, 349);
  @include adaptive(height, 59);
  border: 1px solid rgba(228, 228, 228, 0.6);
  border-radius: 5px;
  @include adaptive-p(12);
  position: relative;
  
  &:focus {
    box-shadow: 0px 4px 4px rgba(50, 50, 71, 0.08),
                0px 4px 8px rgba(50, 50, 71, 0.06);
  }

  &.--opened {
    .header-panel__menu {
      display: block;
    }
  }

  &__inner {
    &--avatar {
      border: 1px solid rgba(228, 228, 228, 0.6);
      @include icon(35, 35);
      border-radius: 50%;
      overflow: hidden;
      //@include adaptive-circle(35);
      //background: $green; 
      @include adaptive(margin-right, 12); 
    }
  
    &--name {
      flex-grow: 1;
      font-weight: 700;
      @include adaptive-fz(font-size, 13, 10);
      @include adaptive(line-height, 18);
      color: $primary-black;
    }
  
  
    &--icon {
      display: block;
      @include icon();
      transform: rotate(90deg);
      filter: $filter-gray-3;
    }  
  }

  &__menu {
    display: none;
    position: absolute;
    z-index: 50;
    @include adaptive(right, 1);
    width: 100%;
    margin: 0;
    padding: 0;
    @include adaptive(margin-top, 24);
    list-style-type: none;
    filter: drop-shadow(0px 4px 4px rgba(50, 50, 71, 0.08))
            drop-shadow(0px 4px 8px rgba(50, 50, 71, 0.06));
    

    &__item {      
      background: $white;

      a {
        @include adaptive-p(12);
      }

      &:hover {
        background: $primary-gray-4;
      }

      &--icon {
        @include icon();
      }

      &--text {
        color: $primary-gray-2;
        font-weight: 400;
        @include adaptive-fz(font-size, 13, 10);
        @include adaptive(line-height, 18);
      }
    }
  }
}

// ------------------------------------------
@media only screen and (max-width: 1380px) {
  // от 1280 до 375
  .header-panel {
    @include adaptive(width, 249);
    @include adaptive(height, 49);
    @include adaptive-p(6);
  }
}

// ------------------------------------------
@media only screen and (max-width: 1100px) {
  // от 1100 (table), 375 и ниже
  .header-panel {
    width: fit-content;
    @include adaptive(height, 43); 
    @include adaptive-p(2);

    &__inner {
      &--avatar {
        order: 2;
        margin: 0;
      }

      &--name {
        display: none;
      }

      &--icon {
        order: 1;
      }
    }

    &__menu {
      @include adaptive(width, 230);
      @include adaptive(margin-top, 12);

      &__item {
        &--text {
          @include adaptive-fz(font-size, 13, 3, $max: 15px);
        }
      }
    }
  }
}