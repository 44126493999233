@use 'styles/utils';
@use 'ui/buttons/close-btn';

.popup {
  padding: 15px;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  visibility: hidden;
  transition: opacity, visibility;
  transition-timing-function: ease-in-out;
  transition-duration: .3s;
  opacity: 0;
  background-color: hsla(140, 100%, 2%, 0.8);

  &.opened {
    visibility: visible;
    opacity: 1;
  }

  &__inner {
    margin: auto;
    position: relative;
  }

  .close-btn {
    z-index: 30;
    top: 20px;
    right: 20px;
  }
}

@media only screen and (max-width: 1100px) {
  .popup {
    width: 100vw;
  }
}

@media only screen and (max-width: 450px) {
  .popup {
    .close-btn {
      top: 5px;
      right: 5px;
    }
  }
}