@use 'src/styles/general/variables' as *;
@use 'src/styles/utils/mixins' as *;

.pageshare {
  @include adaptive(gap, 4);
  @include adaptive-p(4);
  position: fixed;
  z-index: 25;
  @include adaptive(top, 116);
  @include adaptive(right, 80);
  @include adaptive(width, 70);
  @include adaptive(height, 136);

  .pageshare__grid-icon {
    @include adaptive-p(16);
    @include adaptive(width, 62);
    @include adaptive(height, 62);
    border-radius: 50%;
    background: $primary-black;

    img {
      @include icon(30, 30);
    }
  }

  .pageshare__share-icon {
    justify-content: center;

    img {
      @include icon();
    }
  }

  .pageshare__text {
    justify-content: center;
    //@include adaptive-fz(font-size, 7, 2, $max: 8px);
    font-size: 7px;
    font-weight: 500;
    @include adaptive(line-height, 22);
    text-transform: uppercase;
  }
}

// ------------------------------------------
@media only screen and (max-width: 1380px){
  //от 1440 до 1280
  .pageshare {
    @include adaptive(right, 60);
  }
}

// ------------------------------------------
@media only screen and (max-width: 1100px){
  // от 1100 (tablet), 375 и ниже
  .pageshare {
    display: none;
  }
}