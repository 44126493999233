@use 'media' as *;

@mixin reset-list($display: none) {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin clear-list {
  @include reset-list;
  li {
    padding-left: 0;
    text-indent: initial;
    color: var(--body_font);

    &::before {
      display: none;
    }
  }
  li + li {
    margin-top: 0;
  }
}

@mixin transition($property, $duration: .3s, $timing: ease-in-out) {
  transition-property: $property;
  transition-timing-function: $timing;
  transition-duration: $duration;
}

@mixin circle($diameter) {
  --diameter: #{$diameter};
  width: var(--diameter);
  height: var(--diameter);
  border-radius: 50%;
}

@mixin adaptive-circle($diameter) {
  @include adaptive(--diameter, $diameter);
  width: var(--diameter);
  height: var(--diameter);
  border-radius: 50%;
}

@mixin adaptive-fz($property, $initialValue, $mod: 1, $unit: px, $min: 1.2rem, $max: 900rem) {
  $value: calc(#{$initialValue} * #{$mod} * var(--screen-dif) + #{$initialValue} * 1#{$unit});
  #{$property}: clamp(#{$min}, #{$value}, #{$max});
}

@mixin adaptive($property, $initialValue, $mod: 1, $unit: px) {
  #{$property}: calc(#{$initialValue} * #{$mod} * var(--screen-dif) + #{$initialValue} * 1#{$unit})
}

@mixin custom-scroll($width, $track-color, $thumb-color, $height: initial) {
  scrollbar-width: #{$width};
  scrollbar-color: #{$thumb-color};

  &::-webkit-scrollbar {
    width: #{$width};
    height: #{$height};
  }

  &::-webkit-scrollbar-thumb {
    background-color: #{$thumb-color};
  }

  &::-webkit-scrollbar-track {
    background-color: #{$track-color};
  }
}

@mixin transparent-scroll() {
  @include custom-scroll(0, transparent, transparent, 0);
}

// icon mixin

@mixin icon($width: 28, $height: 28) {
  @include adaptive(width, $width);
  @include adaptive(height, $height);
  //width: $width;
  //height: $height;
  object-fit: fill;
  object-position: center;
}

// paddings

@mixin adaptive-px($val) {
  @include adaptive(padding-left, $val);
  @include adaptive(padding-right, $val);
}

@mixin adaptive-py($val) {
  @include adaptive(padding-top, $val);
  @include adaptive(padding-bottom, $val);
}

@mixin adaptive-p($val) {
  @include adaptive(padding, $val);
}

// margins

@mixin adaptive-mx($val) {
  @include adaptive(margin-left, $val);
  @include adaptive(margin-right, $val);
}

@mixin adaptive-my($val) {
  @include adaptive(margin-top, $val);
  @include adaptive(margin-bottom, $val);
}

@mixin adaptive-m($val) {
  @include adaptive(margin, $val);
}

@mixin mx-auto() {
  margin-left: auto;
  margin-right: auto;
}

/// Show / Hide
@mixin show() {
  opacity: 1;
  visibility: visible;
}

@mixin hide() {
  opacity: 0;
  visibility: hidden;
}

// Font
@mixin font($fw: 400, $fs: 13, $line-h: 16, $max-fs: 0, $min-fs: 0) {
  font-weight: $fw;
  @include adaptive-fz(font-size, $fs, 10);
  @include adaptive(line-height, $line-h);

  @media only screen and (max-width: 1100px) {
    // от 1100 (table), 375 и ниже
    @if($max-fs == 0 and $min-fs == 0) {
      @include adaptive-fz(font-size, $fs, 3);
    } @else if($max-fs == 0 and $min-fs != 0) {
      @include adaptive-fz(font-size, $fs, 3, $min: $min-fs);
    } @else if($max-fs != 0 and $min-fs == 0) {
      @include adaptive-fz(font-size, $fs, 3, $max: $max-fs);
    } @else {
      @include adaptive-fz(font-size, $fs, 3, $min: $min-fs, $max: $max-fs);
    }
  }
}

/// Block Element
/// @param {String} $element - Element's name
@mixin element($element) {
  &__#{$element} {
      @content;
  }
}
/// Block Modifier
/// @param {String} $modifier - Modifier's name
@mixin modifier($modifier) {
  &--#{$modifier} {
      @content;
  }
}

