@use 'src/styles/general/variables' as *;
@use 'src/styles/utils/mixins' as *;

.menu {
  width: 100%;
  height: fit-content;
  border-radius: 0px 0px 12px 12px;
  background: $white;
  position: absolute;
  z-index: 30;
  @include adaptive(right, 1);
  //@include adaptive(margin-top, 20);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  display: none;

  &.--opened {
    display: block;
  }

  &__mobile-header {
    display: none;
  }

  &__inner {
    @include adaptive-px(52);
    @include adaptive-py(52);  
    @include adaptive(gap, 36);
    align-items: flex-start;

    &__links {
      list-style-type: none;
      padding: 0;
      margin: 0;
      flex-grow: 1;
      flex-basis: calc(100% / 4 - 36px * 2);
      @include adaptive(padding-right, 36);
      border-right: 1px solid rgba(228, 228, 228, 0.6);
      @include adaptive(gap, 24);

      .item {
        width: 100%;
        position: relative;

        &__link {
          @include adaptive(gap, 12);
          margin: 0;
          width: 100%;

          &.--active + .item__submenu {
            display: flex;
          }

          &.--active .item__link__icon.--gray {
            transform: rotate(90deg);
          }

          &__icon {
            @include icon();

            &.--gray {
              filter: $filter-gray-3;
            }
          }

          &__text {
            color: $primary-black;
            font-weight: 500;
            @include adaptive-fz(font-size, 13, 10);
            @include adaptive(line-height, 16); 
            flex-grow: 1;   
          }
        }

        &__submenu {
          list-style-type: none;
          padding: 0;
          display: none;
          flex-direction: column;
          @include adaptive(gap, 12);
          @include adaptive(margin-left, 28);
          @include adaptive(margin-top, 12);

          &__link {
            width: 100%;
            flex-grow: 1;
            color: $primary-black;
            font-weight: 500;
            @include adaptive-fz(font-size, 13, 10);
            @include adaptive(line-height, 16); 
          }
        }
      }
    }

    &__about {
      flex-grow: 1;
      flex-basis: calc(100% / 4 - 36px);
      @include adaptive(gap, 36);

      .title {
        display: block;
        font-weight: 600;
        @include adaptive-fz(font-size, 14, 10);
        @include adaptive(line-height, 17);
        color: $primary-gray-3;
        @include adaptive(margin-bottom, 12);
      }

      &__soc-nets {
        .soc-net-links {
          list-style-type: none;
          padding: 0;
          margin: 0;
          @include adaptive(gap, 24);
  
          &__item {
            &--icon {
              @include icon();
            }
          }
        }  
      }

      &__tags {        
        .list {
          list-style-type: none;
          padding: 0;
          margin: 0;
          flex-wrap: wrap;
          @include adaptive(gap, 12);

          &__item {
            color: $primary-gray-2;
            font-weight: 600;
            @include adaptive-fz(font-size, 14, 10);
            @include adaptive(line-height, 17);
          }
        }
      }

      &__envy-school {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include adaptive(width, 390);
        @include adaptive(height, 52);
        @include adaptive(gap, 8);
        @include adaptive-p(12);
        background: $primary-gray-4;
        border-radius: 8px;
        @include adaptive(margin-top, 123);
        align-self: flex-end;

        span {
          flex-grow: 1;
          font-weight: 500;
          @include adaptive-fz(font-size, 13, 10);
          @include adaptive(line-height, 16);
          color: $primary-black;

          &:last-child {
            text-align: center;
            font-weight: 700;
            text-decoration: underline;
            text-transform: uppercase;
          }
        }

        .envy-icon {
          @include icon(32, 28);
        }
      }
    }
  }

  &__nav {
    @include adaptive-px(52);
    @include adaptive-py(20);
    border-top: 1px solid rgba(228, 228, 228, 0.6);
    justify-content: space-between;

    &__list {
      list-style-type: none;
      padding: 0;
      margin: 0;
      @include adaptive(gap, 12);

      &.--mobile {
        display: none;
      }

      &__item {
        &__link {
          @include adaptive(gap, 12);
           
          .icon {
            @include icon();
          }

          .text {
            color: $primary-black;
            font-weight: 500;
            @include adaptive-fz(font-size, 13, 10);
            @include adaptive(line-height, 16);
          }
        }
      }
    }

    &__close {
      position: relative;
      @include adaptive(width, 25);
      @include adaptive(height, 25);
      display: flex;
      justify-content: center;
      align-items: center;
    
      &::before, 
      &::after {
        content: '';
        display: block;
        position: absolute;
        @include adaptive(width, 3);
        @include adaptive(height, 25);
        background: $primary-black;
      }
    
      &::before {
        rotate: -45deg;
      }
    
      &::after {
        rotate: 45deg;
      }
    }
  }
}

// ------------------------------------------
@media only screen and (max-width: 1700px) {
  //от 1440 до 1280
  .menu {
    &__inner {
      --flex-basis: calc(100% / 3 - 36px);
      flex-wrap: wrap;
      gap: 0;
      @include adaptive-fz(column-gap, 36);
      @include adaptive-px(20);
      
      &__links {
        flex-basis: var(--flex-basis);
        width: var(--flex-basis);

        &:nth-child(1) {
          order: 1;
          flex-grow: 0;
        }

        &:nth-child(2) {
          order: 2;
          flex-grow: 0;
        }

        &:nth-child(3) {
          flex: 0 0 var(--flex-basis);
          width: var(--flex-basis);
          order: 4;
        }
      }

      &__about {
        flex-basis: var(--flex-basis);
        width: var(--flex-basis);
        order: 3;
        flex-grow: 0;
        //flex-shrink: 1;

        &__envy-school{
          margin: 0;
          width: 100%;
        }
      }
    }
  }
}

// ------------------------------------------
@media only screen and (max-width: 1100px) {
  // от 1100 (table), 375 и ниже
  .menu {
    border-radius: 10px;
    border: 1px solid $primary-gray-4;

    &__mobile-header {
      width: 100%;
      display: flex;
      @include adaptive-px(12);
      @include adaptive(padding-bottom, 24);
      @include adaptive(padding-top, 12);

      &__close {
        //flex-grow: 1;
        align-self: flex-end;
        position: relative;
        @include adaptive(width, 25);
        @include adaptive(height, 25);
        display: flex;
        justify-content: center;
        align-items: center;
      
        &::before, 
        &::after {
          content: '';
          display: block;
          position: absolute;
          @include adaptive(width, 2);
          @include adaptive(height, 17);
          background: $primary-black;
        }
      
        &::before {
          rotate: -45deg;
        }
      
        &::after {
          rotate: 45deg;
        }
      }

      &__title {
        //flex-grow: 1;
        align-self: flex-start;
        color: $primary-black;
        font-weight: 500;
        @include adaptive-fz(font-size, 13, 2);
        @include adaptive(line-height, 16);
      }
    }

    &__inner {
      flex-direction: column;
      @include adaptive(gap, 24);
      padding: 0;
      @include adaptive-px(12);
      //@include adaptive(padding-top, 36);
      @include adaptive(padding-bottom, 24);

      &__links {
        padding: 0;
        border: 0;

        &:nth-last-child(n+1) {
          flex: 1 0 100%;
          width: 100%;
        }

        &:nth-child(n+2) {
          border-top: 1px solid rgba(228, 228, 228, 0.6);
        }

        .item {
          &__link {
            &__text {
              @include adaptive-fz(font-size, 13, 3, $max: 14px);
            }  
          }

          &__submenu {
            &__link {
              @include adaptive-fz(font-size, 13, 3, $max: 14px);
            }
          }
        }
      }

      &__about {
        display: none;
      }
    }

    &__nav {
      @include adaptive-px(12);
      @include adaptive-py(24);

      &__list.--desktop {
        display: none;
      }
      
      &__close.--desktop {
        display: none;
      }

      &__list.--mobile {
        display: flex;
        flex-direction: column;
      }

      &__list {
        width: 100%;
        position: relative;
        list-style-type: none;
        padding: 0;
        margin: 0;

        .item {
          &__link {
            @include adaptive(padding-left, 5);
            @include adaptive(gap, 12);
            margin: 0;
            width: 100%;
            background: $primary-black;
            border-radius: 8px;

            &.--active {
              background: $green;
              transition: .1s;
            }
  
            &.--active + .item__submenu {
              display: flex;
            }
  
            &__icon {
              @include icon();
              filter: $filter-white;
            }
  
            &__text {
              color: $white;
              font-weight: 500;
              @include adaptive-fz(font-size, 13, 2);
              @include adaptive(line-height, 16); 
              flex-grow: 1;   
            }
          }
  
          &__submenu {
            list-style-type: none;
            padding: 0;
            display: none;
            flex-direction: column;
            @include adaptive(gap, 12);
            @include adaptive(margin-left, 28);
            @include adaptive(margin-top, 12);
  
            &__link {
              width: 100%;
              flex-grow: 1;
              color: $primary-black;
              font-weight: 500;
              @include adaptive-fz(font-size, 13, 2);
              @include adaptive(line-height, 16); 
            }
          }  
        }
      }
    }    
  }
}