@use 'styles/utils' as *;

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;

  &::before, &::after {
    content: '';
    position: absolute;
    width: 2.6px;
    height: 26px;
    border-radius: 4px;
    background-color: #909090;
    @include transition(background-color);
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &:hover {
    &::before,
    &::after {
      background-color: darken(#909090, 25%);
    }
  }
}

.close-btn_bg {
  @extend .close-btn;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08), 0 10px 20px rgba(0, 0, 0, 0.06);

  &::before,
  &::after {
    width: 2px;
    height: 20px;
  }
}

@media only screen and (max-width: 450px) {
  .close-btn {
    width: 35px;
    height: 35px;

    &::after,
    &::before {
      height: 20px;
    }
  }
}