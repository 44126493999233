@use 'styles/utils';
@use 'ui/buttons/close-btn';
@use 'src/styles/general/variables' as *;
@use 'src/styles/utils/mixins' as *;

.popup-login {

  .popup-content {
    background-color: white;
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    border-radius: 8px;
  }

  .login {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px 24px 36px 24px;

      &__form {
          display: flex;
          flex-direction: column;
      }
  
  }

	.social {
		&__title {
			font-size: 14px;
			color: #000000;
			@include adaptive(margin-bottom, 36);
		}

		&__items {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-gap: 10px;
		
			&__link {
				padding: 18px 40px;
				border: 1px solid $gray;
				border-radius: 10px;

			}
		}
	} 
	

  .welcome {
    background: $green;
    border-radius: 8px 0 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    &__title {
        color: $white;
        font-weight: 500;
        @include adaptive-fz(font-size, 14, 10);
        font-size: 14px;
        line-height: 125%;
        @include adaptive(margin-bottom, 36);
    }

    &__logo {
        @include adaptive(margin-bottom, 36);
        @include icon(177, 70);
        filter: $filter-white;
    }

    &__register {
        position: absolute;
        z-index: 5;
        font-weight: 700;
        @include adaptive(line-height, 20);
        color: $primary-black;
        text-decoration-line: underline;

        bottom: 140px;
        left: 60px;

        &__text {
            @include adaptive(margin-left, 8);
        }
    }
    
  }

  &__inner {
    margin: auto;
    position: relative;
  }

  .close-btn {
    display: none
  }
  
  .input-field  {
    @include adaptive(margin-bottom, 20);
    position: relative;
  
    label {
      font-size: 14px;
      color: #000000;
  
    }
  
    input {
      margin-top: 12px;
      padding: 13px 12px;
      border-radius: 10px;
      width: 327px;
      border: 1px solid $gray;
  
    }
    
    img {
      position: absolute;
      bottom: 12px;
      right: 12px;
    }
  }
  
  .policy {
    @include adaptive(margin-bottom, 20);
    display: flex;
    align-items: center;
  
  }
  
  .login-btn {
    text-align: center;
    cursor: pointer;
    background-color: $primary-black;
    font-weight: 600;
    font-size: 16px;
    padding: 12px 24px;
    color: $white;
    border-radius: 10px;
  
    @include adaptive(margin-bottom, 20);
  }
  
}


@media only screen and (max-width: 1100px){
  .welcome {
    display: none !important;
  }

  .popup-content {
    display: block !important;
    width: 370px;
  }
}

@media only screen and (max-width: 400px){
  .popup-content {
    width: 350px;

    display: flex;
    justify-content: center;

    .login__form {
      width: 290px;
    }

    .input-field {
      input {
        width: 290px;
      }
    }

    .social {
      text-align: center;
    }
  }
}

@media only screen and (max-width: 360px){
  .popup-content {
    width: 330px;
    .login__form {
      width: 240px;
    }
    .input-field {
      input {
        width: 240px;
      }
    }
    .social {
      text-align: center;

      &__items {
      
        &__link {
          padding: 15px 20px;
        }
      }
    } 
  }
}

@media only screen and (max-width: 320px){
  .popup-content {
    width: 290px;
    .login__form {
      width: 240px;
    }
    .input-field {
      input {
        width: 240px;
      }
    }
    .social {
      &__items {
        &__link {
          padding: 15px 20px;
        }
      }
    } 
  }
}

@media only screen and (max-width: 300px){
  .popup-content {
    width: 250px;
    .login__form {
      width: 200px;
    }
    .input-field {
      input {
        width: 200px;
      }
    }
    .social {
      &__items {
        &__link {
          padding: 10px 15px;
        }
      }
    } 
  }
}