:root {
  --init-width: 1920;
  --init-width-units: calc(var(--init-width) * 1px);
  --screen-dif: calc((100vw - var(--init-width-units)) / (var(--init-width) / 100) / 100);

  @media only screen and (max-width: 1700px) {
    --init-width: 1440;
  }

  @media only screen and (max-width: 1380px) {
    --init-width: 1280;
  }

  // Возможно ещё промежуточный между 1370 и 600 понадобится
  @media only screen and (max-width: 1100px) {
    --init-width: 900;
  }

  @media only screen and (max-width: 600px) {
    --init-width: 375;
  }
}

// colors
$primary-black: #151522;
$green: #00CCCC;
$white: #fff;
$gray: #D8DADC;
$gray-light: #FAFAFA;

$primary-blue: #0084F4;

$primary-gray-2: #666666;
$primary-gray-3: #999999;
$primary-gray-4: #F0F0F0;

$border-color: rgba(228, 228, 228, 0.6);

// svg filters
$filter-black: invert(0%) sepia(0%) saturate(0%) hue-rotate(324deg) brightness(96%) contrast(104%);
$filter-gray-3: invert(65%) sepia(5%) saturate(0%) hue-rotate(231deg) brightness(92%) contrast(96%);
$filter-green: invert(71%) sepia(59%) saturate(3367%) hue-rotate(132deg) brightness(93%) contrast(100%);
$filter-white: invert(0%) sepia(100%) saturate(10%) hue-rotate(275deg) brightness(126%) contrast(96%);
$filter-blue: invert(38%) sepia(29%) saturate(6459%) hue-rotate(190deg) brightness(98%) contrast(102%);
