@use 'src/styles/general/variables' as *;
@use 'src/styles/utils/mixins' as *;

.footer {
  .footer__desktop {
    display: flex;
    flex-direction: column;
    @include adaptive-p(36);
    @include adaptive(gap, 60);

    .footer__desktop__logo-wrap {
      display: flex;
      justify-content: start;

      .footer__desktop__logo {
        @include icon(211, 88);
        filter: $filter-green;
      }
    }

    .footer__desktop__info {
      display: flex;
      @include adaptive(padding-bottom, 36);

      .footer__desktop__info__nav {
        list-style-type: none;
        display: flex;
        flex-grow: 1;
        flex-flow: row wrap;
        @include adaptive(row-gap, 24);
        padding: 0;

        li {
          flex-grow: 1;
          flex-basis: 25%;

          .link {
            color: $primary-black;
            font-weight: 400;
            @include adaptive-fz(font-size, 16, 10, $min: 1.4rem);
            @include adaptive(line-height, 20);
          }
        }
      }

      .footer__desktop__info__envy-school {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        @include adaptive(gap, 12);

        .envy-school__block {
          display: flex;
          justify-content: space-between;
          align-items: center;
          @include adaptive(width, 424);
          @include adaptive(height, 52);
          @include adaptive(gap, 8);
          @include adaptive-p(12);
          background: $primary-gray-4;
          border-radius: 8px;

          span {
            flex-grow: 1;
            font-weight: 500;
            @include adaptive-fz(font-size, 13, 10);
            @include adaptive(line-height, 16);
            color: $primary-black;

            &:last-child {
              text-align: center;
            }
          }

          .envy-icon {
            @include icon(32, 28);
          }
        }

        .envy-school__org {
          font-weight: 400;
          @include adaptive-fz(font-size, 14, 10);
          @include adaptive(line-height, 22);
          color: $primary-gray-2;
        }
      }
    }

    .footer__nav {
      display: flex;
      justify-content: space-between;
      list-style-type: none;
      padding: 0;
      margin: 0;
      @include adaptive-py(24);
      border-top: 1px dashed $border-color;

      li {
        a {
          font-weight: 400;
          @include adaptive-fz(font-size, 14, 10);
          @include adaptive(line-height, 22);
          color: $primary-gray-2;
        }
      }
    }
  }

  .footer__mobile {
    display: none;
  }
}

// ------------------------------------------
@media only screen and (max-width: 1700px){
  //от 1440 до 1280
  .footer {
    .footer__desktop {
      @include adaptive-p(24);
      @include adaptive(gap, 24);

      .footer__desktop__info {
        .footer__desktop__info__nav {
          column-gap: .5rem;

          li {
            flex-basis: calc(25% - .5rem);

            /*.link {
              font-weight: 400;
              @include adaptive-fz(font-size, 16, 10);
              @include adaptive(line-height, 20);
            }*/
          }
        }

        .footer__desktop__info__envy-school {
          @include adaptive(max-width, 288);
  
          .envy-school__block {
            @include adaptive(width, 288);
            @include adaptive(height, 68);
            @include adaptive(border-radius, 12);
    
            span {
              word-spacing: 100px;

              &:last-child {
                font-weight: 700;
                @include adaptive-fz(font-size, 17, 12);
                @include adaptive(line-height, 22);
                text-decoration: underline;
              }
            }
          }
  
          .envy-school__org {
            text-align: right;
          }
        }  
      }

      .footer__nav {
        gap: .5rem;

        li {
          flex-grow: 1;

          &:nth-child(5) {
            flex-grow: 6;
            text-align: right;
          }

          &:nth-last-child(1) {
            text-align: right;
          }
        }
      }
    }
  }  
}

// ------------------------------------------
@media only screen and (max-width: 1380px){
  // от 1280 до 375
}

// ------------------------------------------
@media only screen and (max-width: 1100px){
  // от 1100 (table), 375 и ниже
  .footer {
    .footer__desktop {
      display: none;
    }

    .footer__mobile {
      @include adaptive(border-top-left-radius, 12);
      @include adaptive(border-top-right-radius, 12);
      background: $green;
      display: flex;
      flex-direction: column;
      @include adaptive(gap, 36);

      @include adaptive-p(24);

      .footer__mobile__nav {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        @include adaptive(row-gap, 28);
        
        &__item {
          &__btn {
            width: 100%;
            display: flex;
            align-items: center;

            &.--active {
              & .icon {
                transform: rotate(90deg);
                transition: transform .3s ease-out;
              }

              & + .footer__mobile__nav__item__sub-menu {
                //display: flex;
                max-height: 500px;
                transition: max-height .5s ease-out;
              }
            }
  
            .text {
              flex-grow: 1;
              font-weight: 600;
              @include adaptive-fz(font-size, 14, 3);
              @include adaptive(line-height, 17);
              color: $primary-black;
            }
  
            .icon {
              @include icon();
              transition: .5s ease-out;
            }
          }

          &__sub-menu {
            //display: none;
            overflow: hidden;
            max-height: 0;
            transition: max-height .5s ease-out;

            &__inner {
              list-style-type: disc;
              @include adaptive(margin-left, 24);
              @include adaptive(margin-top, 24);
              padding: 0;
              @include adaptive(gap, 24);

              &__item {
                &--link {
                  font-weight: 600;
                  @include adaptive-fz(font-size, 14, 3);
                  @include adaptive(line-height, 17);
                  color: $primary-black;  
                }
              }  
            }
          }
        }
      }

      .footer__mobile__address {
        display: flex;
        flex-direction: column;
        //@include adaptive(height, 69);
        @include adaptive(gap, 9);

        span {
          font-weight: 400;
          @include adaptive-fz(font-size, 16, 3);
          @include adaptive(line-height, 20);
          color: $primary-black;  
        }
      }

      .footer__mobile__envy-school {
        display: flex;
        justify-content: space-between;
        align-items: center;
        //@include adaptive(width, 288);
        @include adaptive(height, 56);
        @include adaptive(border-radius, 12);
        background: $white;
        @include adaptive(gap, 8);
        @include adaptive-p(12);

        span {
          flex-grow: 1;
          //word-spacing: 100px;
          font-weight: 500;
          @include adaptive-fz(font-size, 13, 3);
          @include adaptive(line-height, 22);

          &:last-child {
            font-weight: 700;
            @include adaptive-fz(font-size, 17, 3);
            @include adaptive(line-height, 22);
            text-decoration: underline;
            color: $primary-black;
            text-align: center;
          }
        }
      }

      .footer__mobile__payments {
        @include adaptive(height, 24);
        @include adaptive-p(4);
        background: $white;
        @include adaptive(border-radius, 8);

        .pay-img {
          width: 100%;
          @include adaptive(height, 16);
        }
      }

      .footer__mobile__social-net {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .net-icon {
          @include icon(35, 35);
        }
      }

      .footer__mobile__logo {
        display: flex;
        justify-content: center;

        .logo {
          @include icon(170, 69)
        }
      }

      .footer__mobile__org {
        display: flex;
        flex-direction: column;

        span {
          font-weight: 400;
          @include adaptive-fz(font-size, 14, 3);
          @include adaptive(line-height, 22);
          color: $primary-black;
          text-align: center;
        }
      }
    }
  }
}